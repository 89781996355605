import React, { useState, useEffect } from "react";

import "./Pager.css";

export default function Pager(props) {
  const [selectedPage, setSelectedPage] = useState(parseInt(props.currentPage));
  const totalPages = Math.ceil(props.resultCount / props.resultsPerPage);

  useEffect(
    (_) => {
      props.setCurrentPage(selectedPage);
    },
    [selectedPage]
  );

  function goToNextPage() {
    setSelectedPage(selectedPage + 1);
  }

  function goToPreviousPage() {
    setSelectedPage(selectedPage - 1);
  }

  var i = 0;
  var page_links = [];

  var minPage = 0;
  var maxPage = totalPages - 1;

  if (selectedPage - minPage > 2) {
    minPage = selectedPage - 2;
  }

  if (maxPage - selectedPage > 2) {
    maxPage = parseInt(selectedPage) + 2;
  }

  for (i = minPage; i <= maxPage; i++) {
    if (i === parseInt(selectedPage)) {
      page_links.push(
        <li className="page-item active" key={i}>
          <span className="page-link">{i + 1}</span>
        </li>
      );
    } else {
      page_links.push(
        <li className="page-item" key={i}>
          <button className="page-link" id={i} onClick={(e) => setSelectedPage(parseInt(e.currentTarget.id))}>
            {i + 1}
          </button>
        </li>
      );
    }
  }

  var previousButton;
  if (parseInt(selectedPage) === 0) {
    previousButton = (
      <li className="page-item disabled" key="prev">
        <span className="page-link">&#8592;</span>
      </li>
    );
  } else {
    previousButton = (
      <li className="page-item" key="prev" onClick={goToPreviousPage}>
        <button className="page-link">&#8592;</button>
      </li>
    );
  }

  var nextButton;
  if (parseInt(selectedPage) === totalPages - 1) {
    nextButton = (
      <li className="page-item disabled" key="next">
        <span className="page-link"> &#8594;</span>
      </li>
    );
  } else {
    nextButton = (
      <li className="page-item" key="next">
        <button className="page-link" onClick={goToNextPage}>
          &#8594;
        </button>
      </li>
    );
  }

  return (
    <nav aria-label="..." className="pager">
      <ul className="pagination item">
        {previousButton}
        {page_links}
        {nextButton}
      </ul>
    </nav>
  );
}

import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const navigate = useNavigate();

  /* const handleSubmit = (e) => {
    e.preventDefault();
    console.log("An email was submitted: " + email);
    console.log("A password was submitted: " + password);
  }; */

  const handleSubmit = async (e) => {
    setIsLoading(true);
    setLoginFailed(false);
    e.preventDefault();

    const response = await fetch(process.env.REACT_APP_AZURE_LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_AZURE_API_KEY,
      },
      body: JSON.stringify({ user: username, password: password }),
    });

    // if (true) {
    if (response.status === 200) {
      // Login riuscito
      //setIsAuthenticated(true);
      setIsLoading(false);
      setLoginFailed(false);
      sessionStorage.setItem("isAuth", "true");
      window.location.reload();
    } else {
      // Login fallito
      //setIsAuthenticated(false);
      setLoginFailed(true);
      setIsLoading(false);
      sessionStorage.setItem("isAuth", "false");
    }
  };

  return (
    <div className="container3">
      <div className="right-half">
        <div
          className="login-form"
          style={{
            backgroundColor: "white",
            width: "50%",
            height: "60%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginBottom: "1em" }}>Benvenuto</h2>
          <Form style={{ width: "80%" }} onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="label-input">Username</Form.Label>
              <Form.Control type="Username" onChange={(e) => setUsername(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" style={{ marginBottom: "1em" }}>
              <Form.Label className="label-input">Password</Form.Label>
              <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Button className="btn btn-primary" type="submit" disabled={isLoading}>
              Login
              {isLoading && <CircularProgress style={{ marginLeft: "10px" }} color="inherit" size={"0.8rem"} />}
            </Button>
          </Form>
          {loginFailed && (
            <Alert style={{ marginTop: "0.5em" }} variant={"danger"}>
              Le credenziali inserite non sono corrette. <br />
              Riprovare
            </Alert>
          )}
        </div>
      </div>
      <div className="left-half"></div>
    </div>
  );
};

export default Login;

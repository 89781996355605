import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";

import Results from "../../components/Results/Results";
import Pager from "../../components/Pager/Pager";
import Facets from "../../components/Facets/Facets";
import SearchBar from "../../components/SearchBar/SearchBar";

import "./Search.css";
/* import { CircularProgress } from "@material-ui/core"; */

export default function Search() {
  let location = useLocation();
  let history = useNavigate();

  const [results, setResults] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [q, setQ] = useState(new URLSearchParams(location.search).get("q") ?? "*");
  const [top] = useState(10);
  const [skip, setSkip] = useState(new URLSearchParams(location.search).get("skip") ?? 0);
  const [filters, setFilters] = useState([]);
  const [facets, setFacets] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFacets, setSelectedFacets] = useState([]);

  let resultsPerPage = top;

  /*  useEffect(() => {
    // Recupera l'array dal localStorage
    const savedFacets = JSON.parse(localStorage.getItem("filtri")) || [];
    setSelectedFacets(savedFacets);
  }, [facets, q]); */

  const sortedFacets = Object.keys(facets)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = facets[key];

      return accumulator;
    }, {});

  useEffect(() => {
    setIsLoading(true);
    const savedFacets = JSON.parse(localStorage.getItem("filtri")) || [];
    setSelectedFacets(savedFacets);

    const body = {
      nameIndex: "azureblob-index",
      searchText: q,
      searchFields: savedFacets,
      size: 10,
      pageIndex: currentPage,
    };

    const headers = {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_AZURE_API_KEY,
    };

    axios
      .post(process.env.REACT_APP_AZURE_DOCUMENTS_URL, body, {headers: headers})
      .then((response) => {
        setResults(response.data.docs);
        setFacets(response.data.facets);
        setResultCount(response.data.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [q, skip, filters]);

  let postSearchHandler = (searchTerm) => {
    // pushing the new search term to history when q is updated
    // allows the back button to work as expected when coming back from the details page
    history("/search?q=" + searchTerm);
    setCurrentPage(0);
    setSkip(0);
    // setFilters([]);
    setQ(searchTerm);
  };

  let updatePagination = (newPageNumber) => {
    setCurrentPage(newPageNumber);

    setSkip(newPageNumber * top);
  };

  var body;
  if (isLoading) {
    body = (
      <div style={{ textAlignLast: "center", placeSelf: "center" }} className="col-md-9">
        <CircularProgress size={"4rem"} />
      </div>
    );
  } else {
    body = (
      <div className="col-md-9">
        <Results documents={results} top={top} skip={skip} count={resultCount}></Results>
        <Pager className="pager-style" currentPage={currentPage} resultCount={resultCount} resultsPerPage={resultsPerPage} setCurrentPage={updatePagination}></Pager>
      </div>
    );
  }

  return (
    <main className="main main--search container-fluid">
      <div className="row">
        <div className="col-md-3">
          <div className="search-bar">
            <SearchBar postSearchHandler={postSearchHandler} q={q}></SearchBar>
          </div>
          <Facets facets={sortedFacets} filters={filters} setFilters={setFilters}></Facets>
        </div>
        {body}
      </div>
    </main>
  );
}

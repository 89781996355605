import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import summaries from "./../../../summaries_genai.json";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import "./Result.css";
import { Grid } from "@material-ui/core";

export default function Result(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalGenAIOpen, setModalGenAIOpen] = useState(false);
  const [isModalTagOpen, setModalTagOpen] = useState(false);
  const [docGenAI, setDocGenAI] = useState({});
  const [docs, setDocs] = [summaries.docs];

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openGenAIModal = (title) => {
    setModalGenAIOpen(true);

    const doc = docs.find(f => f.FilePath === title);

    if (doc)
      setDocGenAI(doc);
  };

  const closeGenAIModal = () => {
    setModalGenAIOpen(false);
  };

  const openTagModal = () => {
    setModalTagOpen(true);
  };

  const closeTagModal = () => {
    setModalTagOpen(false);
  };

  const convertTitle = (title) => {
    let formattedTitle = title;
    if (title) {
      if (title.includes(".")) {
        formattedTitle = title.slice(0, title.lastIndexOf("."))
      }
    } else {
      formattedTitle = "Titolo non estratto";
    }
    return formattedTitle
  };

  const checkFileAvailable = (title) => {
    let ret = false;
    const check = docs.find(f => f.FilePath === title);

    if (check)
      ret = true;

    return ret;
  }

  return (
    <>
      <div
        className="card result"
        data-toggle="tooltip"
        data-placement="bottom"
        title={props.document.summary}
      >
        <div className="card-header" style={{ padding: "0.75rem 0.5rem", display: "flex", justifyContent: "space-between" }}>
          <div>
            <h6 className="title-style" style={{ textAlign: "left" }}>
              <span style={{ fontSize: "18px" }}>
                <b>Titolo:</b>
              </span>{" "}
              <br />
              {convertTitle(props.document.metadata_storage_name)}
            </h6>
          </div>
          <div style={{ padding: "0.75rem 0.5rem", display: "flex", justifyContent: "space-between" }}>
            <a className="link" onClick={() => openTagModal()} title="Dettagli Tag">#Tag</a>
            {checkFileAvailable(props.document.metadata_storage_name) && <div style={{ textAlign: "end" }}>
              <button className="btn btn-primary btn-sm rounded-1" type="button" onClick={() => openGenAIModal(props.document.metadata_storage_name)}>Dettagli</button>
            </div>}
          </div>
        </div>
        <div onClick={openModal} className="card-body" style={{ padding: "0.75rem 0.5rem" }}>
          <p style={{ textAlign: "left" }}>
            <b>Descrizione:</b> <br />
            <i>
              {props.document.summary !== null
                ? props.document.summary?.slice(0, 300) + "..."
                : "Descrizione non estratta"}
            </i>
          </p>
        </div>
      </div>

      <Modal size="xl" show={isModalOpen} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>
            {convertTitle(props.document.metadata_storage_name)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <div dangerouslySetInnerHTML={{ __html: props.document.content }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={isModalGenAIOpen} onHide={closeGenAIModal}>
        <Modal.Header>
          <Modal.Title>
            <h3>{docGenAI?.Titolo}</h3>
            <p style={{ fontSize: "0.8em", fontWeight: 400 }}><b>Autori:</b> {docGenAI?.Autori} - <b>Tipologia:</b> {docGenAI?.Tipologia}  {docGenAI?.Rivista && <>- <b>Rivista:</b> {docGenAI?.Rivista}</>}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {docGenAI?.Riassunto}
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontStyle: "italic" }}>Generated By <b>GenAI</b></div>
          <Button variant="secondary" onClick={closeGenAIModal}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="modalTag" show={isModalTagOpen} onHide={closeTagModal}>
        <Modal.Header>
          <Modal.Title>
            <h3>Tag</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} justifyContent="space-around"
            >
            {props.document.diagnosis && props.document.diagnosis.length > 0 &&
              <div container >
                <div className="headerTag"><h5>Diagnosis</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.diagnosis.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>}
            {props.document.examinationName && props.document.examinationName.length > 0 &&
              <div container>
                <div className="headerTag"><h5>Examination Name</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.examinationName.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>}
            {props.document.medicationClass && props.document.medicationClass.length > 0 &&
              <div container>
                <div className="headerTag"><h5>Medication Class</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.medicationClass.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>}
            {props.document.medicationName && props.document.medicationName.length > 0 &&
              <div container>
                <div className="headerTag"><h5>Medication Name</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.medicationName.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>}
            {props.document.symptomOrSign && props.document.symptomOrSign.length > 0 &&
              <div container>
                <div className="headerTag"><h5>Symptom or Sign</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.symptomOrSign.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>}
            {props.document.people && props.document.people.length > 0 &&
              <div container>
                <div className="headerTag"><h5>People</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.people.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>}
            {/* {props.document.locations && props.document.locations.length > 0 &&
              <div container>
                <div className="headerTag"><h5>Locations</h5></div>
                <Divider orientation="horizontal" flexItem />
                <div>
                  <ul className="listaTag">
                    {
                      props.document.locations.map(el =>
                        <li>{el}</li>)
                    }
                  </ul></div>
              </div>} */}
            {props.document.organizations && props.document.organizations.length > 0 && 
            <div container>
              <div className="headerTag"><h5>Organizations</h5></div>
              <Divider orientation="horizontal" flexItem />
              <div>
                <ul className="listaTag">
                  {
                    props.document.organizations.map(el =>
                      <li>{el}</li>)
                  }
                </ul></div>
            </div>}
            
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeTagModal}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

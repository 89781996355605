import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// Context for user authentication
import { AuthContext } from "../contexts/AuthContext";
// App shell components
import AppHeader from "../components/AppHeader/AppHeader";
// React Router page components
import Home from "../pages/Home/Home";
import Search from "../pages/Search/Search";
//import Details from '../pages/Details/Details';
// Bootstrap styles, optionally with jQuery and Popper
import "bootstrap/dist/css/bootstrap.min.css";
// Custom app styles
import "./App.css";
import Login from "../pages/Login/Login";
import AppFooter from "../components/AppFooter/AppFooter";

function App() {
  // React Hook: useState with a var name, set function, & default value
  const [user, setUser] = useState({});
  const Auth = sessionStorage.getItem("isAuth");

  // Fetch authentication API & set user state DA TRASFORMARE IN LOGIN
  async function fetchAuth() {
    const response = await fetch("/.auth/me");
    if (response) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response
          .json()
          .then((response) => setUser(response))
          .catch((error) => console.error("Error:", error));
      }
    }
  }

  // React Hook: useEffect when component changes
  // Empty array ensure this only runs once on mount
  useEffect(() => {
    fetchAuth();
  }, []);

  return (
    <AuthContext.Provider value={user}>
      <div className="app">
        <AppHeader />
        <Router>
          <Routes>
            {Auth === "true" ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<Search />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
            {/*}    <Route path="/details/:id" component={Details} />*/}
          </Routes>
        </Router>
        <AppFooter />
      </div>
    </AuthContext.Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { List } from "@material-ui/core";
import CheckboxFacet from "./CheckboxFacet/CheckboxFacet";
import styled from "styled-components";
import "./Facets.css";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

export default function Facets(props) {
  const [storageFacets, setStorageFacets] = useState([]);
  function mapFacetName(facetName) {
    const capitalizeFirstLetter = (string) => (string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "");
    facetName = facetName.trim();
    facetName = capitalizeFirstLetter(facetName);

    facetName = facetName.replace("_", " ");
    return facetName;
  }

  function addFilter(name, value) {
    const newFilters = props.filters.concat({ field: name, value: value });
    setStorageFacets([...storageFacets, { fieldName: name, operatorType: 0, fieldValue: value }]);
    props.setFilters(newFilters);
  }

  useEffect(() => {
    /*  localStorage.removeItem("filtri"); */
    localStorage.setItem("filtri", JSON.stringify(storageFacets));
  }, [storageFacets]);

  function removeFilter(filter) {
    const newFilters = props.filters.filter((item) => item.value !== filter.value);
    const arrayAggiornato = storageFacets.filter((item) => item.fieldValue !== filter.value);
    setStorageFacets(arrayAggiornato);
    props.setFilters(newFilters);
  }

  function formatDate(dateString) {
    // Verifica se la stringa contiene il carattere ';'
    const hasSemicolon = dateString.includes(";");

    if (hasSemicolon) {
      // Se c'è il carattere ';', taglia la stringa prima di ';'
      dateString = dateString.split(";")[0].trim();
      return dateString;
    }

    const date = moment(dateString, "YYYY-MM-DD", true); // Assumendo che il formato di input sia 'YYYY-MM-DD'

    if (!date.isValid()) {
      return dateString; // Non è una data valida, restituisci la stringa originale o tagliata
    }

    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  }

  var facets;
  try {
    facets = Object.keys(props.facets).map((key) => {
      return (
        <CheckboxFacet
          key={key}
          name={key}
          values={props.facets[key]}
          addFilter={addFilter}
          removeFilter={removeFilter}
          mapFacetName={mapFacetName}
          selectedFacets={props.filters.filter((f) => f.field === key)}
        />
      );
    });
  } catch (error) {
    console.log(error);
  }

  const filters = props.filters.map((filter, index) => {
    return (
      <li key={index}>
        <Box sx={{ width: "90%", marginTop: 1 }}>
          <Chip
            onDelete={() => removeFilter(filter)}
            className="chip"
            sx={{
              padding: "0.5em",
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            }}
           label={`${mapFacetName(filter.field)}: ${formatDate(filter.value)}`}
          />
        </Box>
      </li>
    );
  });

  return (
    <div id="facetPanel" className="box">
      <div className="facetbox">
        <div id="clearFilters">
          <ul className="filterlist">{filters}</ul>
        </div>
        <FacetList component="nav" className="listitem">
          {facets}
        </FacetList>
      </div>
    </div>
  );
}

const FacetList = styled(List)({
  marginTop: "32px !important",
});

import React from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar/SearchBar";
import cognitiveSearch from "../../images/cognitive-search.png";

import "./Home.css";
import "../../pages/Search/Search.css";

export default function Home() {
  const history = useNavigate();
  const navigateToSearchPage = (q) => {
    if (!q || q === "") {
      q = "*";
    }
    history("/search?q=" + q);
  };

  return (
    <main className="main main--home">
      <div className="row home-search">
        <img className="logo" src={cognitiveSearch} alt="Cognitive Search"></img>
        <SearchBar postSearchHandler={navigateToSearchPage}></SearchBar>
      </div>
    </main>
  );
}

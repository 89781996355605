import React from "react";
import Result from "./Result/Result";

import "./Results.css";

export default function Results(props) {
  let results = props.documents?.map((result, index) => {
    return <Result key={index} document={result.document} />;
  });

  let beginDocNumber = Math.min(props.skip + 1, props.count);

  let endDocNumber = Math.min(props.skip + props.top, props.count);

  return (
    <div>
      <p className="results-info">
        {!props.count ? "Non ci sono risultati da mostrare" : `Visualizzazione ${beginDocNumber}-${endDocNumber} di ${props.count?.toLocaleString()} risultati`}
      </p>
      <div className="row row-cols-sm-1 results">{results}</div>
    </div>
  );
}
